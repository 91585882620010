<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="modelRef" @finish="onSubmit" :labelCol="{ span: 4 }" :wrapperCol="{span: 16}">
				<!-- <a-form-item label="是否允许卡券支付" :rules="[{ required: true, message: '必填项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.isCouponPay">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item> -->

				<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
					商城订单支付设置</h4>

				<a-form-item label="待支付限时" name="unPayTime" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number disabled v-model:value="modelRef.unPayTime" placeholder="请输入" :min="0"></a-input-number> 分钟
					<div class="ui-text">
						当待支付的订单超出设置的时间，则订单关闭
					</div>
				</a-form-item>

				<a-form-item label="订单完成时间" name="signTime" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number v-model:value="modelRef.signTime" placeholder="请输入" :min="0"></a-input-number> 天
					<div class="ui-text">
						从发货时起算，达到该设置的时间，系统将自动确认收货，即订单已完成。
					</div>
					<div class="ui-text">
						注：0则为不自动收货，如用户不点击【确认收货】，此设置会导致订单一直停留在待收货状态，请谨慎操作
					</div>
				</a-form-item>

				<h4 style="margin-top: 40px;padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
					影票订单支付设置</h4>

				<a-form-item label="锁座限时" name="ticketUnPayTime" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number v-model:value="modelRef.ticketUnPayTime" placeholder="请输入" :min="0"></a-input-number> 分钟
					<div class="ui-text">
						当待支付的订单超出设置的时间，则订单关闭
					</div>
				</a-form-item>
				
				<h4 style="margin-top: 40px;padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
					卖品订单支付设置</h4>
				
				<a-form-item label="待支付限时" name="unPayTime" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number disabled v-model:value="modelRef.unPayTime" placeholder="请输入" :min="0"></a-input-number> 分钟
					<div class="ui-text">
						当待支付的订单超出设置的时间，则订单关闭
					</div>
				</a-form-item>

				<h4 style="margin-top: 40px;padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
					分账手续费比例设置</h4>

				<a-form-item label="分账手续费比例" name="serviceFeeScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number v-model:value="modelRef.serviceFeeScale" placeholder="请输入" :min="0.01" :max="5" :precision="2"></a-input-number> %
					<div class="ui-text">
						不能大于百分之五
					</div>
				</a-form-item>
				
				<a-row>
					<a-col :offset="6" style="margin-top: 40px;">
						<a-button v-permission="['mall_order_setting_submit']" type="primary" html-type="submit">提交</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import { getOrderSetting, updateOrderSetting } from '@/service/modules/order.js';
	export default {
		data() {
			return {
				loading: false,
				modelRef: {
					unPayTime: 0,
					ticketUnPayTime: 0,
					signTime: 0,
					serviceFeeScale: 1
				}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData(){
				this.loading = true;
				try {
					let ret = await getOrderSetting({});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.isCouponPay = 1;
						this.modelRef = ret.data;
						if (!this.modelRef.serviceFeeScale) {
							this.modelRef.serviceFeeScale = 1;
						}
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				this.loading = true;
				try {
					let ret = await updateOrderSetting(this.modelRef);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('设置成功');
					}
				} catch(e) {
					this.loading = false;
				}
			}
		}
	}
</script>

<style scoped>
	.ui-text {
		color: red;
	}
</style>
